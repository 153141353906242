import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomBreadCrumb from 'components/Breadcrumb'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledVacatures = styled.div``

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 767px) {
    margin-top: -60px;
  }
`

const StyledImg = styled(Img)`
  pointer-events: none;
  height: 100%;
  z-index: -1;
`

const ImgContainer = styled.div`
  position: relative;
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const Vacatures = ({ pageContext }) => {
  const { 
      page: {
        path,
        title,
        acf,
        yoastMeta
      },
      vacatures: { edges: vacatures }
    } = useStaticQuery(graphql`
    query PrivacyDeclarationQuery {
      page: wordpressPage(wordpress_id: {eq: 297}) {
        title
        path
        content
        acf {
          banner {
            title
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        yoastMeta: yoast_meta {
          name
          content
          property
        }
      }
      vacatures: allWordpressWpVacature {
        edges {
          node {
            title
            slug
            wordpress_id
          }
        }
      }
      site {
        siteMetadata {
          defaultTitle: title
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} />
      <StyledVacatures>
        <ImgContainer>
          <StyledImg className="d-flex" alt="" fluid={acf.banner.image.localFile.childImageSharp.fluid} />
          <ImageContainerText className="container">
            <div className="title font-size-xxl font-weight-xl">
              <h2 className="color-text-light font-weight-xl">{title}</h2>
            </div>
          </ImageContainerText>
        </ImgContainer>
        <CustomBreadCrumb data={title} className="py-2" pageContext={pageContext} />
        <div className="container pt-4">
          <div className="row">
            <div className="col-12">
              {vacatures.map(vacature => (
                <Vacature vacature={vacature.node} key={vacature.node.wordpress_id} />
              ))}
            </div>
          </div>
        </div>
        <div className="py-1 py-md-5" />
        <div className="py-1 py-md-5" />
        <div className="py-1 py-md-5" />
        <div className="py-md-5" />
        <div className="py-md-5" />
      </StyledVacatures>
    </Layout>
  )
}

export default Vacatures

const StyledVacature = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
  border-radius: 10px;
`

const VacatureTitle = styled.h2`
  font-weight: ${props => props.theme.font.weight.xl};

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const StyledCustomLink = styled(CustomLink)`
  background-color: ${props => props.theme.color.contrast};
  border-radius: 5px;
  transition: .25s;

  p {
    color: ${props => props.theme.color.secondary};
  }

  &:hover {
    transform: scale(1.05);
  }
`

const LinkWrapper = styled.div`
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const Vacature = ({ vacature, className }) => {
  return (
    <StyledVacature className={`px-5 py-5 ${className ? `${className}` : ``}`}>
      <div className="d-flex flex-wrap justify-content-between">
        <VacatureTitle className="mb-3 text-lg-left text-center mb-lg-0">{vacature.title}</VacatureTitle>
        <LinkWrapper className="d-flex d-lg-block justify-content-center">
          <StyledCustomLink className="d-flex px-4 py-2 align-items-center" to={`/vacatures/${vacature.slug}`}>
            <p className="mb-0 font-weight-xl">Bekijk vacature</p>
          </StyledCustomLink>
        </LinkWrapper>
      </div>
    </StyledVacature>
  )
}